import React, { useState, useEffect } from 'react';
import Posts from "./Post";
import Pagination from "./Pagination";
import axios from 'axios';
import Recent from "./Recent";
import {Link} from "react-router-dom";
const App = () => {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(5);

	useEffect(() => {
		const fetchPosts = async () => {
			setLoading(true);
			const res = await axios.get('https://blog.aplswd.com/wp-json/wp/v2/posts');
			setPosts(res.data);
			setLoading(false);
		};


		fetchPosts();
	}, []);

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

	// Change page
	const paginate = pageNumber => setCurrentPage(pageNumber);

	return (
		<div >

			<div className="positioning ">
				<div className="container-fluid blog">
					<div className="container">
						<div className="row align-items-center justify-content-between">
							<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
								<div className="hrrd-hero-content text-center">
									<h1 className="section-heading">News</h1>

									<p><Link to="/">Home</Link> / <Link to="/news" >News</Link></p>
								</div>
							</div>
							<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="space-bar"></div>

			<div className="blog-post-area ">
				<div className="container">
					<div className="row">

						<div className="col-md-8 col-sm-12 col-12">
							<div >
								<Posts posts={currentPosts} loading={loading} />
								<div align="center" className="pt-5 pb-4">
								<Pagination
									postsPerPage={postsPerPage}
									totalPosts={posts.length}
									paginate={paginate}
								/>
							</div></div>

						</div>

						<div className="col-md-4 col-sm-12 col-12">
							<Recent/>
						</div>

					</div>
				</div>
			</div>



		</div>
	);
};

export default App;