import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RecentItem from './RecentItem';
import {Link} from "react-router-dom";
const App = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);


    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            const res = await axios.get('https://blog.aplswd.com/wp-json/wp/v2/posts?per_page=6&page=1',
                {
                    params:{
                        _limit: 5
                    }
                });
            console.log(res.data);
            setPosts(res.data);
            setLoading(false);
        };

        fetchPosts();
    }, []);



    return (
        <div>
            <div className="cart-wrap">

                <div className="single-cart">

                    <div className="cart-title">
                        <h2>Recent Posts</h2>
                    </div>
                    {posts.map(post => (
                        <RecentItem key={post.id} book={post} />
                    ))}



                </div>


            </div>


        </div>
    );
};

export default App;