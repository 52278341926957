import React, {Component} from "react";
import axios from "axios";
import PropType from 'prop-types';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet
} from "react-router-dom";
export class Books extends Component{
     state ={
         imgUrl:'',
         author:'',
         isLoaded:false,
     }

    static PropType = {
        book:PropType.object.isRequired
    }

    componentDidMount() {
        const {featured_media, author} = this.props.book;
        console.log(featured_media);
        const getAuthor = axios.get(`https://blog.aplswd.com/wp-json/wp/v2/users/${author}`);
        const getImageUrl = axios.get(`https://blog.aplswd.com/wp-json/wp/v2/media/${featured_media}`);

        Promise.all([getImageUrl, getAuthor]).then(res => {
            this.setState({
                imgUrl:res[0].data.media_details.sizes.medium.source_url,
                author:res[1].data.name,
                isLoaded:true,

            })
        })
    }

    render() {
     const {title , excerpt, id, slug} = this.props.book;
     const {author, imgUrl, isLoaded} =this.state;

     if(isLoaded){
         return(
             <>
                 <div className="row align-items-center g-4 g-lg-4 g-md-4 g-sm-4 g-xl-5 pb-4 pt-4">
                     <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                         <div className="blog-post-img">
                             <img src={imgUrl} alt={title.rendered} />
                         </div>
                     </div>
                     <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                         <div className="blog-post-wrap">
                             <h4>{title.rendered}</h4>
                             <small>{author}</small>

                             <div className="paragraph_text" dangerouslySetInnerHTML={{ __html: excerpt.rendered}}></div>
                             <p>....</p>
                             <Link  to={`/news-details/${slug}`}  className="mt-3"> Read More</Link>
                             <Outlet />
                         </div>
                     </div>
                 </div>

             </>
         )
     }
     return null;

 }
}

export default Books