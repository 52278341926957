import React, {Component} from "react";
import axios from "axios";
import PropType from 'prop-types';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet
} from "react-router-dom";
export class Books extends Component{
    state ={
        imgUrl:'',
        author:'',
        isLoaded:false,
    }

    static PropType = {
        book:PropType.object.isRequired
    }

    componentDidMount() {
        const {featured_media, author} = this.props.book;
        console.log(featured_media);
        const getAuthor = axios.get(`https://blog.aplswd.com/wp-json/wp/v2/users/${author}`);
        const getImageUrl = axios.get(`https://blog.aplswd.com/wp-json/wp/v2/media/${featured_media}`);

        Promise.all([getImageUrl, getAuthor]).then(res => {
            this.setState({
                imgUrl:res[0].data.media_details.sizes.thumbnail.source_url,
                author:res[1].data.name,
                isLoaded:true,

            })
        })
    }

    render() {
        const {title , excerpt, id, slug} = this.props.book;
        const {author, imgUrl, isLoaded} =this.state;

        if(isLoaded){
            return(
                <>
                    <div className="cart-post">
                        <div className="cart-post-img">
                            <img src={imgUrl} alt={title.rendered} />
                        </div>
                        <div className="cart-post-content">
                            <h4><a>{title.rendered}
                                </a></h4>

                            <div className="paragraph_text1" dangerouslySetInnerHTML={{ __html: excerpt.rendered}}></div>
                            <p className="pt-0 recent_dot">....</p>
                            <Link to={`/news-details/${slug}`}  className="mt-3"> Read More</Link>
                            <Outlet />
                        </div>
                    </div>



                </>
            )
        }
        return null;

    }
}

export default Books